import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, useTheme, useMediaQuery } from '@material-ui/core'
import TopNavBar from '../components/TopNavBar'
import journeyImage from '../assets/img/luke-ellis-craven-735992-unsplash.jpg'
import quoteImage from '../assets/img/anton-darius-thesollers-271293-unsplash.jpg'
import PersonCard from '../components/About Us/PersonCard'
import IanDownlingImage from '../assets/img/Ian Dowling.jpg'
import TamaraDowlingImage from '../assets/img/Tamara Dowling 1.jpg'
import MarcieCramerImage from '../assets/img/Marcie Cramer.jpeg'
import DeanneMillerImage from '../assets/img/Deanne Miller.png'
import BarbaraSteffensImage from '../assets/img/Barbara Steffens.jpg'
import HopeRayImage from '../assets/img/Hope Ray.jpg'
import JulieMcLeanImage from '../assets/img/Julie McLean.jpeg'
import ChristopherBurnsImage from '../assets/img/Christopher Burns.jpeg'
import Footer from '../components/Footer'
import CoreBeliefs from '../components/About Us/CoreBeliefs'
import OurStory from '../components/About Us/OurStory'
import JoinToday from '../components/About Us/JoinToday'
import ParticipateInChange from '../components/About Us/ParticipateInChange'
import Headers from '../components/Headers'

const useStyles = makeStyles((theme) => ({
  landing: {
    display: 'flex',
    width: '100%',
    padding: '80px 200px',
    paddingTop: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '96px',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '24px',
      padding: '32px 16px',
      paddingTop: '48px',
    },
  },
  journeyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '40px',
    flexShrink: '0',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      gap: '24px',
    },
  },
  journeyText: {
    display: 'flex',
    flexDirection: 'column',
    color: '#1B3666',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '130%',
    letterSpacing: '-0.72px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      letterSpacing: '-0.48px',
    },
  },
  journeyDescription: {
    color: '#000',
    fontSize: '20px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.18px',
  },
  journeyImg: {
    width: '454px',
    height: '454px',
    borderRadius: '6px',
    objectFit: 'cover',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 32px 19px 0px rgba(0, 0, 0, 0.05), 0px 56px 23px 0px rgba(0, 0, 0, 0.01), 0px 88px 25px 0px rgba(0, 0, 0, 0.00)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '450px',
      height: '257px',
      boxShadow: 'none',
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '80px',
    padding: '80px 112px',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
      gap: '24px',
    },
  },
  lowercaseTitle: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-0.6px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  descriptionText: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.18px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  titleContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  differenceTitle: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    textTransform: 'uppercase',
  },
  missionCardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  missionCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '358px',
    padding: '32px',
    gap: '24px',
    borderRadius: '6px',
    backgroundColor: '#F9FAFB',
    flex: 1,
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 59px 16px 0px rgba(0, 0, 0, 0.00)',
  },
  missionCardTitle: {
    color: '#111827',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
    textAlign: 'center',
    letterSpacing: '-0.312px',
  },
  missionCardText: {
    color: '#6D7280',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '-0.18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  quoteImage: {
    width: '100%',
    height: '647px',
    objectFit: 'cover',
    flexShrink: '0',
    [theme.breakpoints.down('sm')]: {
      height: '390px',
    },
  },
  quoteContainer: {
    position: 'absolute',
    left: '77px',
    top: '33%',
    display: 'flex',
    width: '631px',
    // height: '210px',
    padding: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    flexShrink: 0,
    background: 'rgba(255, 255, 255, 0.15)',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '16px',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  },
  quoteText: {
    color: '#FFF',
    fontSize: '32px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 800,
    lineHeight: '130%',
    letterSpacing: '-0.48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  quoteAuthor: {
    color: '#F9FAFA',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
    letterSpacing: '-0.312px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  bodCardsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '50px',
    [theme.breakpoints.down('sm')]: {
      gap: '24px',
    },
  },
}))

const VideoURL = 'https://drive.google.com/file/d/1-OfIoLzfRt_Ma_BiuUT17tFMJMxw3wJJ/preview'

const bodDetails = [
  {
    image: IanDownlingImage,
    name: 'Ian Dowling',
    text: 'Co-founder',
  },
  {
    image: TamaraDowlingImage,
    name: 'Tamara Dowling',
    text: 'Co-founder',
  },
  {
    image: JulieMcLeanImage,
    name: 'Julie McLean',
    text: 'Business Advisor',
  },
  {
    image: MarcieCramerImage,
    name: 'Marcie Cramer',
    text: 'LMHC',
  },
]

const advisoryDetails = [
  {
    image: ChristopherBurnsImage,
    name: 'Christopher Burns',
    text: 'LCSW, CSAT, EMDR',
  },
  {
    image: HopeRayImage,
    name: 'Hope Ray',
    text: 'LPC, CSAT, CHFP, CCPS',
  },
  {
    image: DeanneMillerImage,
    name: 'Deanne Miller',
    text: 'Licensed Psychotherapist',
  },
  {
    image: BarbaraSteffensImage,
    name: 'Barbara Steffens',
    text: 'PhD, CCPS-S, CPC-S',
  },
  
]

export default function AboutUs() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Headers
        title="About Lit Path"
        type="website"
        description="Learn the origin story of Lit Path and why we are so passionate about helping others afford access to addiction recovery therapy. Find information about our founders, board, mission, and core beliefs."
      />
      <TopNavBar />
      <div className={classes.landing}>
        <Grid container spacing={4} style={{ maxWidth: '1200px' }}>
          <Grid item xs={12} md={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <div className={classes.journeyContainer}>
              <Typography className={classes.journeyText} align="left" component="h1">
                Journey to Recovery
              </Typography>
              {isMobile ? (
                <img
                  align="center"
                  className={classes.journeyImg}
                  src={journeyImage}
                  alt="a person standing on a cliff looking at a valley during sunrise"
                />
              ) : null}
              <Typography className={classes.journeyDescription} align="left" component="p">
                At Lit Path, we understand the challenges individuals face when seeking recovery therapy, including the
                financial burden that can make healing seem unattainable, perpetuating a cycle of chaos and despair.
              </Typography>
            </div>
          </Grid>
          {!isMobile && (
            <Grid align="center" item md={6} xs={12}>
              <img
                align="center"
                className={classes.journeyImg}
                src={journeyImage}
                alt="a person standing on a cliff looking at a valley during sunrise"
              />
            </Grid>
          )}
        </Grid>
      </div>
      <OurStory />

      <div className={classes.container}>
        <Typography className={classes.lowercaseTitle} component="h2">
          Mission and Vision
        </Typography>
        <div className={classes.missionCardsContainer}>
          <div className={classes.missionCard}>
            <Typography className={classes.missionCardTitle}>Mission</Typography>
            <Typography className={classes.missionCardText}>
              Bringing hope to those struggling with compulsive behavior(s) through connection and assistance with
              mental health care that is right for them because every individual is unique and valued.
            </Typography>
          </div>
          <div className={classes.missionCard}>
            <Typography className={classes.missionCardTitle}>Vision</Typography>
            <Typography className={classes.missionCardText}>
              A world where mental health care is available, affordable, and free from stigmatism for every individual.
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Why Lit Path?
          </Typography>
          <Typography className={classes.descriptionText} component="p">
            In this video, our co-founders, Ian and Tamara Dowling talk about why Lit Path was created, what we do, and
            how to support us.
          </Typography>
        </div>
        <div className={classes.videoContainer}>
          <iframe
            src={VideoURL}
            width={isMobile ? '350' : '960'}
            height={isMobile ? '195' : '540'}
            allow="autoplay"
          ></iframe>
        </div>
      </div>
      <CoreBeliefs />
      <div style={{ position: 'relative' }}>
        <img src={quoteImage} className={classes.quoteImage} alt="a person holding a lit up paper bag" />
        <div className={classes.quoteContainer}>
          <Typography className={classes.quoteText}>
            "Although the world is full of suffering, it is also full of the overcoming of it"
          </Typography>
          <Typography className={classes.quoteAuthor}>- Helen Keller</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Board of Directors
          </Typography>
          <Typography className={classes.descriptionText} component="p">
            Meet our Lit Path Board of Directors – a team of passionate professionals dedicated to providing hope and
            mental healthcare scholarships to individuals struggling with compulsive behavior(s).
          </Typography>
        </div>
        <div className={classes.bodCardsContainer}>
          {bodDetails.map((bod) => (
            <PersonCard key={bod.name} image={bod.image} name={bod.name} text={bod.text} />
          ))}
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Advisory Board
          </Typography>
          <Typography className={classes.descriptionText} component="p">
            Our esteemed Advisory Board at Lit Path brings invaluable expertise and guidance, shaping our mission to
            provide hope and mental healthcare scholarships to those fighting compulsive behavior(s).
          </Typography>
        </div>
        <div className={classes.bodCardsContainer}>
          {advisoryDetails.map((bod) => (
            <PersonCard key={bod.name} image={bod.image} name={bod.name} text={bod.text} />
          ))}
        </div>
      </div>
      <ParticipateInChange />
      <JoinToday />
      <Footer />
    </>
  )
}
