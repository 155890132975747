import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import LogoTechLess from '../../assets/img/Techless.png'
import ConvenantBanner from '../../assets/img/ConvenantBanner.png'

const useStyles = makeStyles(() => ({
    ToolsContainer: {
        display: 'flex',
        position: 'relative',
        width: '350px',
        height: '350px',
        gap: '24px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
        perspective: '1000px',
        transformStyle: 'preserve-3d',
        cursor: 'pointer',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      },
      frontContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '6px',
        boxShadow:
          '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 59px 16px 0px rgba(0, 0, 0, 0.00)',
        backfaceVisibility: 'hidden',
        transform:'rotateY(0deg)',
        transition: 'transform 1s',
      },
      toolsImage: {
        width: '100%',
        height: '250px',
        objectFit: 'cover',
        borderRadius: '6px 6px 0px 0px',
      },
      toolsTitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        paddingBottom: '30px',
      },
      toolsTitle: {
        color: '#000',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '132%',
        letterSpacing: '-0.312px',
        textAlign: 'center',
      },
      button: {
        padding: '10px',
        borderRadius: '50%',
        flexShrink: 0,
        background: '#E6E6E6',
        border: 'none',
        justifyContent: 'center',
      },
      linksContainer: {
        display: 'flex',
        width: '100%',
        height: '95%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      },
      link: {
        textAlign: 'center',
        transition: 'all 0.2s ease-in-out',
        fontFamily: 'Inter',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
      backResourceTitle: {
        color: '#000',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '132%',
        letterSpacing: '-0.312px',
        textAlign: 'center',
      },
    }))
    
    const Tools = () => {
    
      const classes = useStyles()
      return (
        <>
        <div className={classes.ToolsContainer}>
        <div className={classes.frontContainer}>
            <div className={classes.toolsTitleContainer}>
                 <div className={classes.linksContainer}>
                    <div className={classes.link}>
                        <a href='https://covenanteyes.sjv.io/oqAQ7e' target="_blank" rel="noopener noreferrer">
                        <img src={ConvenantBanner} alt='Convenant' className={classes.toolsImage} />
                        </a>
                    </div>
                    <div className={classes.toolsTitleContainer}>
                        <div>
                            <Typography className={classes.toolsTitle}>Convenant</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
       <div className={classes.ToolsContainer}>
       <div className={classes.frontContainer}>
           <div className={classes.toolsTitleContainer}>
                <div className={classes.linksContainer}>
                   <div className={classes.link}>
                       <a href='https://techless.com?aff=LITPATH' target="_blank" rel="noopener noreferrer">
                       <img src={LogoTechLess} alt='Techless' className={classes.toolsImage} />
                       </a>
                   </div>
                   <div className={classes.toolsTitleContainer}>
                       <div>
                           <Typography className={classes.toolsTitle}>Techless</Typography>
                       </div>
                   </div>
               </div>
           </div>
       </div>
      </div>
      </>
      )
    }
    
    export default Tools
    